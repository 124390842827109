
import { ref, getCurrentInstance, Ref } from 'vue';
import CompanyInfo from './components/CompanyInfo.vue';
import UserInfo from './components/UserInfo.vue';
import ManagerInfo from './components/ManagerInfo.vue';
import { updateTenant } from '@/api/tenant';
import { getShowBool } from '@/utils/permission-show-module';
import useTenant from './useTenant';

export default {
  name: 'TenantEdit',
  components: {
    CompanyInfo,
    UserInfo,
    ManagerInfo,
  },
  setup() {
    // 组件实例
    const instance = getCurrentInstance();

    const isEdit = ref(false);
    const companyRef: Ref<any> = ref(null);

    // 租户详情
    const tenantDetail = ref({
      contact: {},
      manager: {},
    } as any);
    const { fetchTenantInfo } = useTenant();
    const getDetail = async () => {
      const data = await fetchTenantInfo();
      tenantDetail.value = data;
    };

    getDetail();

    const onSave = async () => {
      companyRef.value.formRef.validate(async (validator: boolean) => {
        if (validator) {
          const { nameShort, addr, addrDetail, logoUrl, intro } = tenantDetail.value;
          const updateData: any = {
            nameShort,
            addr,
            addrDetail,
            logoUrl,
            intro,
          };
          // Object.keys(updateData).forEach((key: string) => {
          //   if (updateData[key] === '') {
          //     delete updateData[key];
          //   }
          // });
          const { code } = await updateTenant(updateData);
          if (code === 0) {
            (instance as any).proxy.$message({
              type: 'success',
              message: '更新成功',
            });
            isEdit.value = false;
          }
        }
      });
    };
    return {
      companyRef,
      isEdit,
      tenantDetail,
      onSave,
      getShowBool,
    };
  },
};
