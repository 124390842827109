
import { computed, ref, SetupContext, WritableComputedRef } from 'vue';

interface ManagerInfoInterface {
  userName: string;
  displayName: string;
  primaryMail: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

export default {
  name: 'ManagerInfo',
  emits: ['go', 'submit'],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: { isEdit: boolean; modelValue: any }, ctx: SetupContext) {
    // 表单引用
    const formRef: any = ref(null);

    // 管理员信息
    const managerInfo: WritableComputedRef<ManagerInfoInterface> = computed({
      get: () => props.modelValue?.manager || {},
      set: (newValue: any) => ctx.emit('update:modelValue', newValue),
    });

    // 初始密码校验
    const validatePass = (rule: any, value: string, callback: Function) => {
      if (value !== '') {
        formRef.value.validateField('confirmPassword');
      }
      callback();
    };

    // 密码再次输入校验
    const checkPasswordValidator = (rule: any, value: string, callback: Function) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else {
        if (value !== props.modelValue.manager.password) {
          callback(new Error('两次输入密码不一致'));
        }
        callback();
      }
    };

    // 表单校验规则
    const rules = {
      userName: [
        { required: true, message: '请输入管理员账号' },
        { min: 3, max: 20, message: '管理员账号长度在2到40个字符之间', trigger: 'blur' },
        { pattern: /^[a-zA-Z0-9-]+$/g, message: '包含非法字符，只能输入小写字母、数字、中划线', trigger: 'blur' },
      ],
      displayName: [
        { required: true, message: '请输入管理员中文姓名' },
        { min: 3, max: 20, message: '管理员姓名长度在2到40个字符之间', trigger: 'blur' },
        { pattern: /^[\u4e00-\u9fa5]+$/g, message: '联系人姓名仅支持中文', trigger: 'blur' },
      ],
      phoneNumber: [
        { required: true, message: '请输入管理员电话' },
        { pattern: /^\d{11}$/g, message: '管理员电话输入格式不合法，请重新输入', trigger: ['blur', 'change'] },
      ],
      primaryMail: [
        { required: true, message: '请输入邮箱地址', trigger: 'blur' },
        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] },
      ],
      password: [
        { required: true, message: '请输入初始密码' },
        { min: 8, max: 16, message: '密码长度在8到16位', trigger: 'blur' },
        { validator: validatePass, trigger: 'blur' },
      ],
      confirmPassword: [{ validator: checkPasswordValidator, trigger: 'blur' }],
    };

    return {
      formRef,
      managerInfo,
      rules,
    };
  },
};
