
import { defineComponent, reactive, ref, watch } from 'vue';
import { userInfo } from '@/layout/messageCenter/user-info';
import { getUserProfile, updateUserPassword, updateUserProfile } from '@/api/auth';
import { ElMessage } from 'element-plus';
import { getTenantDetail } from '@/api/tenant';

export default defineComponent({
  name: 'UserManagement',
  setup() {
    const passLoading = ref(false);
    const userSetInfo = reactive({} as any);
    const userRelease = reactive({} as any);
    const statusArr = ref([false, false, false, false, false]);
    const props = ['userName', 'displayName', 'phoneNumber', 'primaryMail', 'password'];
    const labels = ['用户账号', '用户姓名', '联系电话', '电子邮箱', '用户密码'];
    // const rulesArr = [[], [{ require: true, trigger: 'blur', message: '请输入用户名称' }, {}]];

    const currentIndex = ref(0);

    getUserProfile().then((res) => {
      res.data.password = '******';
      Object.keys(res.data).forEach((x, i) => {
        userSetInfo[x] = Object.values(res.data)[i];
        userRelease[x] = Object.values(res.data)[i];
      });
    });

    const checkStatus = (index: number) => {
      statusArr.value[index] = !statusArr.value[index];
    };
    const save = (id: number, prop: string) => {
      if (!userSetInfo[prop]) {
        return ElMessage.error(`${labels[id]} 不得为空！`);
      }
      if (id === 1) {
        const reg = /^[\u4E00-\u9FA5]{2,20}$/;
        if (!reg.test(userSetInfo[prop])) {
          return ElMessage.error('用户姓名长度在2～20字符之间,且为中文');
        }
      }

      if (id === 2) {
        // https://github.com/VincentSit/ChinaMobilePhoneNumberRegex
        const reg =
          /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4(?:(?:10|4[01])\d{3}|[68]\d{4}|[579]\d{2}))\d{6}$/;
        if (!reg.test(userSetInfo[prop])) {
          return ElMessage.error('请输入正确的电话号码');
        }
      }
      if (id === 3) {
        const reg =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!reg.test(userSetInfo[prop])) {
          return ElMessage.error('请输入正确的电子邮箱');
        }
      }
      const item = {} as any;
      item[prop] = userSetInfo[prop];
      updateUserProfile(item).then((res) => {
        if (res.code === 0) {
          checkStatus(id);
          userRelease[prop] = item[prop];
          ElMessage.success('修改成功');
        }
      });
    };
    const cancel = (id: number, prop?: string) => {
      checkStatus(id);
      if (prop) {
        (userSetInfo[prop] as any) = userRelease[prop] as any;
      }
    };

    const dialogFormVisible = ref(false);
    const passForm = reactive({} as any);
    const reWritePass = () => {
      dialogFormVisible.value = true;
    };
    const formRef = ref(null as any);
    const sendPass = () => {
      let viva = true;
      console.log(formRef.value);
      formRef.value.validate((res: any) => {
        viva = res;
      });
      if (!passForm.newPassword || !passForm.confirmPassword || passForm.confirmPassword !== passForm.newPassword) {
        return false;
      }
      if (viva) {
        passLoading.value = true;
        updateUserPassword({ ...passForm })
          .then((res) => {
            if (res.code === 0) {
              dialogFormVisible.value = false;
              ElMessage.success('修改成功');
            }
            passLoading.value = false;
          })
          .catch(() => {
            passLoading.value = false;
          });
      }
    };
    // 初始密码校验
    const validatePass = (rule: any, value: string, callback: Function) => {
      if (value !== '') {
        if (value === userSetInfo?.userName) {
          callback(new Error('密码不能与用户名相同'));
        } else {
          callback();
          formRef.value.validateField('confirmPassword');
        }
      }
      callback();
    };

    // 密码再次输入校验
    const checkPasswordValidator = (rule: any, value: string, callback: Function) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      }
      if (value !== passForm.newPassword) {
        callback(new Error('两次输入密码不一致'));
      }
      callback();
    };

    const formRules = {
      oldPassword: [
        { required: true, message: '请输入原密码' },
        // TODO:原密码不应该加校验
        { min: 8, max: 16, message: '密码长度在8到16位', trigger: 'blur' },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d（!@#$%^&)]{8,16}/,
          message: '只能输入大小写字母、数字、下划线，且必须包含大、小写字母',
          trigger: 'blur',
        },
      ],
      newPassword: [
        { required: true, message: '请输入新密码' },
        { min: 8, max: 16, message: '密码长度在8到16位', trigger: 'blur' },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d（!@#$%^&)]{8,16}/,
          message: '只能输入大小写字母、数字、下划线，且必须包含大、小写字母',
          trigger: 'blur',
        },
        { validator: validatePass, trigger: 'blur' },
      ],
      confirmPassword: [
        { required: true, message: '请再次输入新密码' },
        { validator: checkPasswordValidator, trigger: 'blur' },
      ],
    };
    watch(dialogFormVisible, (nn) => {
      if (!nn) {
        passForm.oldPassword = '';
        passForm.newPassword = '';
        passForm.confirmPassword = '';
      }
    });
    const tenantDetail = ref({} as any);
    const getDetail = async () => {
      const { data } = await getTenantDetail();
      tenantDetail.value = data;
      console.log(data);
    };
    getDetail();

    return {
      userInfo,
      userSetInfo,
      statusArr,
      checkStatus,
      save,
      cancel,
      props,
      labels,
      reWritePass,
      dialogFormVisible,
      passForm,
      sendPass,
      formRules,
      formRef,
      tenantDetail,
      passLoading,
      // rulesArr,
      currentIndex,
    };
  },
});
