
import { computed, WritableComputedRef } from 'vue';
import { IMAGE_UPLOAD } from '@/shared/constant/file';

interface UserInfoInterface {
  name: string;
  phone: string;
  IDCard: string;
  email: string;
  frontPhoto: string;
  reversePhoto: string;
}

export default {
  name: 'UserInfo',
  emits: ['go', 'update:modelValue'],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => ({
        contact: {},
      }),
    },
  },
  setup(props: { isEdit: boolean; modelValue: any }) {
    // 联系人信息
    const userInfo: WritableComputedRef<UserInfoInterface> = computed(() => ({
      ...props.modelValue.contact,
      ...{ sourceUrl: props.modelValue.sourceUrl },
    }));

    return {
      IMAGE_UPLOAD,
      userInfo,
    };
  },
};
