
import { computed, ref, WritableComputedRef, getCurrentInstance, Ref } from 'vue';
import useCompanyInfo from '../utils/tenant-config';
import { SuccessResponse } from '@/types/response';
import { IMAGE_UPLOAD } from '@/shared/constant/file';
import { uploadValidate } from '@/utils/validate';

// 企业信息接口
interface CompanyInfoInterface {
  name: string; // 企业名称
  nameShort: string; // 企业简称
  industryId: string; // 行业
  addr: string; // 省份
  natureId: string; // 企业性质
  addrDetail: string; // 详细地址
  scaleId: string; // 企业规模
  intro: string; // 企业简介
  license: string; // 企业执照号
  licenseUrl: string; // 营业执照
  logoUrl: string; // 企业logo
  sourceUrl: any;
}

export default {
  name: 'CompanyInfo',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: { isEdit: boolean; modelValue: any }) {
    // 组件实例
    const instance = getCurrentInstance();
    // 表单引用
    const formRef: Ref<any> = ref(null);
    // 表单校验规则
    const rules = {
      nameShort: [
        {
          min: 2,
          max: 40,
          message: '企业别称长度在2到40个字符之间',
          trigger: 'blur',
        },
        {
          pattern: /^[\u4e00-\u9fa5|a-zA-Z|()（）]+$/g,
          message: '包含非法字符，只能输入中文、大小写字母及中英文()',
          trigger: 'blur',
        },
      ],
    };

    // 企业信息
    const companyInfo: WritableComputedRef<CompanyInfoInterface> = computed(() => props.modelValue);

    // 行业选项信息
    const industryOptions = ref([] as any[]);

    // 已选行业展示
    const computedIndustryName = computed(
      () => industryOptions.value.filter((item: any) => item.key === companyInfo.value.industryId)[0]?.value || '',
    );

    // 省份选项信息
    const provinceOptions = ref([] as any[]);

    const computedAddrName = computed(
      () => provinceOptions.value.filter((item: any) => String(item.code) === companyInfo.value.addr)[0]?.name || '',
    );

    // 企业性质选项信息
    const natureOptions = ref([] as any[]);

    const computedNatureName = computed(
      () => natureOptions.value.filter((item: any) => item.key === companyInfo.value.natureId)[0]?.value || '',
    );

    // 企业规模选项信息
    const scaleOptions = ref([] as any[]);

    const computedScaleName = computed(
      () => scaleOptions.value.filter((item: any) => item.key === companyInfo.value.scaleId)[0]?.value || '',
    );

    useCompanyInfo().then((res: any) => {
      industryOptions.value = res.industryOptions;
      natureOptions.value = res.natureOptions;
      scaleOptions.value = res.scaleOptions;
      provinceOptions.value = res.provinceOptions;
    });

    // 图片上传大小校验
    const beforeUpload = (file: any) => {
      uploadValidate(instance, file);
    };

    // 企业logo上传成功回调
    const logoUploadSuccess = (res: SuccessResponse<any>, file: { raw: any }) => {
      if (res.code === 0 && res.data?.fileKey) {
        companyInfo.value.logoUrl = res.data.fileKey;
        companyInfo.value.sourceUrl.logoUrl = URL.createObjectURL(file.raw);
      } else {
        (instance as any).proxy.$message({
          type: 'error',
          message: '上传失败，请重新上传！',
        });
      }
    };

    return {
      rules,
      formRef,
      IMAGE_UPLOAD,
      companyInfo,
      industryOptions,
      computedIndustryName,
      provinceOptions,
      computedAddrName,
      natureOptions,
      computedNatureName,
      scaleOptions,
      computedScaleName,
      beforeUpload,
      logoUploadSuccess,
    };
  },
};
