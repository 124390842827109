import { getTenantDetail } from '@/api/tenant';
import { SuccessResponse } from '@/types/response';
import { DeploymentMethod, TenantInfo } from '@/views/conf-management/types';
import { ref, readonly, InjectionKey, Ref } from 'vue';

const tenantInfo = ref<TenantInfo>();
export const TENANT_DEPLOYMENT_METHOD: InjectionKey<Ref<DeploymentMethod>> = Symbol('deploymentMethod');
export default function useTenant() {
  function setDeployment(info: TenantInfo) {
    tenantInfo.value = info;
  }
  async function fetchTenantInfo() {
    try {
      const { data } = (await getTenantDetail()) as SuccessResponse<TenantInfo>;
      setDeployment(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    fetchTenantInfo,
    tenant: readonly(tenantInfo),
  };
}
